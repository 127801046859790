// React component with a form to create a new member of the health team
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import labService from "../../../utils/api/v1/labService";
import { navigate } from "gatsby-link";

// typings
import { Laboratory } from "../../../utils/interfaces/Laboratory";

// components
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import Flex from "../../../components/Containers/Flex";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";
import Wrapper from "../../../components/Containers/Wrapper";
import SButton from "../../../components/Buttons/SButton";
import Error from "../../../components/Errors/Error";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: center;

  ${(props: { margin?: string }) => {
    if (props.margin) {
      return `margin: ${props.margin};`;
    }
  }}

  .MuiFormControl-root {
    margin: 5px 10px;
  }
`;

interface Props {
  id: string;
}

const EditLaboratory = ({ id }: Props): JSX.Element => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [labData, setLabData] = useState<Laboratory>({
    display_name: "",
    address: "",
    city: "",
    country: "",
    active: false,
  });

  const fetchLabData = async () => {
    setLoading(true);
    try {
      const req = await labService.fetchLab(id);
      const data: Laboratory = req.data;
      setLabData(data);
    } catch (err) {
      console.log(err);
      setErrors([err.message]);
    }
    setLoading(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    const errors = checkErrors();
    if (errors) {
      setLoading(false);
      return;
    }

    try {
      const req = await labService.updateLab(id, labData);
      Swal.fire({
        icon: req.status === 200 ? "success" : "error",
        title:
          req.status === 200
            ? "Laboratorio actualizado con éxito"
            : "Error al actualizar laboratorio",
        text:
          req.status === 200
            ? `Información del laboratorio ${labData.display_name} actualizada con éxito`
            : "Error al actualizar el laboratorio",
        showConfirmButton: true,
        didClose: () => {
          navigate(-1);
        },
      });
    } catch (err) {
      console.error(err);
      setErrors([err.message]);
    }

    setLoading(false);
  };

  const formChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const attribute = event.target.id;
    setLabData((prevState: Laboratory): Laboratory => {
      const newState = { ...prevState };
      newState[attribute] = newValue;
      return newState;
    });
  };

  const checkErrors = () => {
    const newErrors = [];

    // check for errors in the form
    if (!labData.display_name.length) {
      newErrors.push("El nombre es obligatorio");
    }
    if (!labData.address.length) {
      newErrors.push("La dirección es obligatoria");
    }
    if (!labData.city.length) {
      newErrors.push("La ciudad es obligatoria");
    }
    if (!labData.country.length) {
      newErrors.push("El país es obligatorio");
    }

    setErrors(newErrors);
    return newErrors.length > 0;
  };

  useEffect(() => {
    fetchLabData();
  }, []);

  return (
    <PrivateRoute>
      <Flex justify="center">
        <Wrapper variant="outlined">
          <h2>Actualizar laboratorio</h2>

          <Error>
            <ul>
              {errors.map((i, key) => (
                <li key={key}>{i}</li>
              ))}
            </ul>
          </Error>
          <Row>
            <FormControlLabel
              control={
                <Switch
                  name="active"
                  color="primary"
                  checked={labData.active}
                  onChange={(e) => {
                    setLabData(
                      (prevState: Laboratory): Laboratory => ({
                        ...prevState,
                        active: e.target.checked,
                      })
                    );
                  }}
                />
              }
              label={labData.active ? "Activo" : "Inactivo"}
            />
          </Row>
          <Row>
            <TextField
              id="display_name"
              onChange={formChange}
              value={labData.display_name}
              helperText="Nombre del laboratorio"
              fullWidth
            />
            <TextField
              id="address"
              onChange={formChange}
              value={labData.address}
              helperText="Dirección"
              fullWidth
            />
          </Row>
          <Row>
            <TextField
              id="city"
              onChange={formChange}
              value={labData.city}
              helperText="Ciudad"
              fullWidth
            />
            <TextField
              id="country"
              onChange={formChange}
              value={labData.country}
              helperText="País"
              fullWidth
            />
          </Row>
          <Flex align="center" justify="center" margin="2rem 0rem 1rem 0rem">
            <SButton
              variant="outlined"
              color="secondary"
              disabled={loading}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </SButton>
            <SButton
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={onSubmit}
            >
              Crear
            </SButton>
          </Flex>
        </Wrapper>
      </Flex>
    </PrivateRoute>
  );
};

export default EditLaboratory;
